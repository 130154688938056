"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateNetsuiteLink = void 0;
/**
 * Generates a NetSuite link based on the provided subdomain, entity type, and entity ID.
 * @param subdomain - The subdomain of the NetSuite instance (e.g., '1234567' or '1234567-sb1').
 * @param entityType - The type of entity (e.g., 'subsidiary', 'billingaccount', 'subscription', 'invoice', etc.).
 * @param entityId - The ID of the entity to link to.
 * @returns A string URL that links to the specified entity in NetSuite.
 */
const generateNetsuiteLink = (subdomain, entityType, entityId, customRecordType) => {
    let baseUrl = `https://${subdomain}.app.netsuite.com/app`;
    switch (entityType) {
        case "subsidiary":
            return `${baseUrl}/common/otherlists/subsidiarytype.nl?id=${entityId}`;
        case "billingaccount":
            return `${baseUrl}/accounting/otherlists/billingaccount.nl?id=${entityId}`;
        case "subscription":
            return `${baseUrl}/accounting/subscription/subscription.nl?id=${entityId}`;
        case "invoice":
            return `${baseUrl}/accounting/transactions/custinvc.nl?id=${entityId}&l=T&whence=`;
        case "customrecord":
            return `${baseUrl}/common/custom/custrecordentry.nl?rectype=${customRecordType}&id=${entityId}`;
        default:
            return "";
    }
};
exports.generateNetsuiteLink = generateNetsuiteLink;
