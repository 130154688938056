import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ServiceCard from "./ServiceCard";
import { useAuth } from "../../contexts/AuthContext"; // Ensure this import path is correct
import { UserRole } from "models/user";

const services = [
  {
    category: "Finance",
    services: [
      {
        title: "Invoicing",
        icon: <ReceiptIcon fontSize="large" />,
        path: "/invoices",
      },
      {
        title: "Subscription QC", // Add Subscription QC service
        icon: <CheckCircleIcon fontSize="large" />,
        path: "/subscription-qc",
        roles: [UserRole.SUPERVISOR, UserRole.ADMIN], // Specify roles that can access this service
      },
      {
        title: "Recurring Invoice QC", // Add Subscription QC service
        icon: <EventRepeatIcon fontSize="large" />,
        path: "/recurring-invoice-qc",
        roles: [UserRole.SUPERVISOR, UserRole.ADMIN], // Specify roles that can access this service
      },
      {
        title: "Exception Invoices", // Add Subscription QC service
        icon: <EventBusyIcon fontSize="large" />,
        path: "/exception-invoices",
        roles: [UserRole.SUPERVISOR, UserRole.ADMIN], // Specify roles that can access this service
      },
      {
        title: "PO # Update / Re-Issue", // Add Subscription QC service
        icon: <NoteAltIcon fontSize="large" />,
        path: "/po-reissues",
        roles: [UserRole.SUPERVISOR, UserRole.ADMIN], // Specify roles that can access this service
      },
    ],
  },
  {
    category: "Admin",
    services: [
      {
        title: "User Management",
        icon: <AdminPanelSettingsIcon fontSize="large" />,
        path: "/users",
      },
    ],
  },
];

const ServiceList: React.FC = () => {
  const { user } = useAuth();
  const isAdmin = user?.policies.some(
    (policy) => policy.role === UserRole.ADMIN
  );
  const isSupervisor = user?.policies.some(
    (policy) =>
      policy.role === UserRole.SUPERVISOR || policy.role === UserRole.ADMIN
  );

  let userHighestRole = UserRole.REQUESTER;

  if (isAdmin) {
    userHighestRole = UserRole.ADMIN;
  } else if (isSupervisor) {
    userHighestRole = UserRole.SUPERVISOR;
  }

  return (
    <Container sx={{ pt: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Services
      </Typography>
      {services.map(
        (category) =>
          (category.category !== "Admin" || isAdmin) && (
            <Box key={category.category} sx={{ mb: 4 }}>
              <Typography variant="h5" component="h2" gutterBottom>
                {category.category}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "left",
                  gap: 2,
                }}
              >
                {category.services.map(
                  (service) =>
                    (!service.roles ||
                      service.roles.includes(userHighestRole)) && (
                      <ServiceCard
                        key={service.title}
                        title={service.title}
                        icon={service.icon}
                        path={service.path}
                      />
                    )
                )}
              </Box>
            </Box>
          )
      )}
    </Container>
  );
};

export default ServiceList;
