import React, { useEffect, useState } from "react";
import { Box, TextField, Chip, useMediaQuery, useTheme } from "@mui/material";
import { useInvoiceFormContext } from "../../../contexts/InvoiceFormContext";
import { useAuth } from "src/contexts/AuthContext";

const AdditionalData: React.FC = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const { user } = useAuth();
  const [emailInput, setEmailInput] = useState<string>("");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
  };

  const handleAddEmail = () => {
    if (
      emailInput &&
      !invoiceFormData.additionalRecipients.includes(emailInput)
    ) {
      setInvoiceFormData((prevData) => ({
        ...prevData,
        additionalRecipients: [...prevData.additionalRecipients, emailInput],
      }));
      setEmailInput("");
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    setInvoiceFormData((prevData) => ({
      ...prevData,
      additionalRecipients: prevData.additionalRecipients.filter(
        (email) => email !== emailToDelete
      ),
    }));
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddEmail();
    }
  };

  useEffect(() => {
    if (
      user?.email &&
      !invoiceFormData.additionalRecipients.includes(user.email)
    ) {
      setInvoiceFormData((prevData) => ({
        ...prevData,
        additionalRecipients: [...prevData.additionalRecipients, user.email],
      }));
    }
  }, []);

  return (
    <Box>
      <TextField
        label="PO Number for Customer"
        name="otherRefNum"
        value={invoiceFormData.poNumber}
        onChange={(e) =>
          setInvoiceFormData({ ...invoiceFormData, poNumber: e.target.value })
        }
        fullWidth
        margin="normal"
      />
      <Box display="flex" alignItems="flex-start" mt={2} flexWrap="wrap">
        <TextField
          label="Additional Email Recipient"
          value={emailInput}
          onChange={handleEmailChange}
          onKeyDown={handleKeyDown}
          sx={{
            flexBasis: isXs ? "100%" : "25%",
            marginRight: 1,
            marginBottom: isXs ? 2 : 0,
          }}
          fullWidth={isXs}
          helperText="Press Enter to add email"
        />
        <Box display="flex" flexWrap="wrap" flexGrow={1} marginTop="12px">
          {invoiceFormData.additionalRecipients.map((email, index) => (
            <Chip
              key={index}
              label={email}
              onDelete={() => handleDeleteEmail(email)}
              color="primary"
              sx={{ marginRight: 1, marginBottom: 1 }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AdditionalData;
