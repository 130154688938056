/**
 * 1	US Dollar	USD
2	British pound	GBP
3	Canadian Dollar	CAD
4	Euro	EUR
5	Japanese Yen	JPY
6	Australian Dollar	AUD
7	Swiss Franc	CHF
8	South African Rand	ZAR
9	Swedish Krona	SEK
10	Hong Kong Dollar	HKD
11	New Zealand Dollar	NZD
12	Danish Krone	DKK
13	Norwegian Krone	NOK
14	Indian Rupee	INR
15	Arab Emirates Dirham	AED
16	RON	RON
17	TRY	TRY
18	ARS	ARS
19	Brazilian Real	BRL
20	Singapore dollar	SGD
21	Polish zloty	PLN
22	ILS	ILS
23	Malaysian Ringgit	MYR
24	Koruna	CZK
25	Colombian Peso	COP
26	Mexican Peso	MXN
27	Chinese Yuan	CNY
28	Philippine peso	PHP
29	Myanmar Kyat	MMK
30	Omani Rial	OMR
31	Bulgarian Lev	BGN
32	New Taiwan Dollar	TWD
 */
export const currencies = [
  { value: 1, label: "USD - US Dollar" },
  { value: 2, label: "GBP - Great British Pound" },
  { value: 3, label: "CAD - Canadian Dollar" },
  { value: 4, label: "EUR - Euro" },
  { value: 5, label: "JPY - Japanese Yen" },
  { value: 6, label: "AUD - Australian Dollar" },
];

export const currencySymbolMap: { [key: string]: string } = {
  "1": "$",
  "2": "£",
  "3": "C$",
  "4": "€",
  "5": "¥",
  "6": "A$",
};

export const getCurrencySymbol = (currencyId: string) => {
  return currencySymbolMap[currencyId] || "";
};
