"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialOutput = exports.InvoiceRequestStatus = exports.StepStatus = exports.InvoiceRequestType = void 0;
var InvoiceRequestType;
(function (InvoiceRequestType) {
    InvoiceRequestType["PS_NEW_BUSINESS"] = "PS_NEW_BUSINESS";
    InvoiceRequestType["RENEWAL"] = "PS_RENEWAL";
    InvoiceRequestType["USAGE"] = "USAGE";
})(InvoiceRequestType || (exports.InvoiceRequestType = InvoiceRequestType = {}));
var StepStatus;
(function (StepStatus) {
    StepStatus["NOT_INITIATED"] = "NOT_INITIATED";
    StepStatus["IN_PROGRESS"] = "IN_PROGRESS";
    StepStatus["COMPLETED"] = "COMPLETED";
    StepStatus["FAILED"] = "FAILED";
})(StepStatus || (exports.StepStatus = StepStatus = {}));
var InvoiceRequestStatus;
(function (InvoiceRequestStatus) {
    InvoiceRequestStatus["COMPLETED"] = "COMPLETED";
    InvoiceRequestStatus["IN_PROGRESS"] = "IN_PROGRESS";
    InvoiceRequestStatus["FAILED"] = "FAILED";
})(InvoiceRequestStatus || (exports.InvoiceRequestStatus = InvoiceRequestStatus = {}));
exports.initialOutput = {
    SubsidiaryCheck: {
        name: "Subsidiary Check",
        status: StepStatus.NOT_INITIATED,
    },
    BillingAccountCreation: {
        name: "Billing Account Creation",
        status: StepStatus.NOT_INITIATED,
    },
    CustomPricingPlansCreation: {
        name: "Custom Pricing Plans Creation",
        status: StepStatus.NOT_INITIATED,
    },
    SubscriptionCreation: {
        name: "Subscription Creation",
        status: StepStatus.NOT_INITIATED,
    },
    ContractualDocumentsCreation: {
        name: "Contractual Document Upload",
        status: StepStatus.NOT_INITIATED,
    },
    DraftItemsActivation: {
        name: "Draft Items Activation",
        status: StepStatus.NOT_INITIATED,
    },
    SubscriptionActivation: {
        name: "Subscription Activation",
        status: StepStatus.NOT_INITIATED,
    },
    HandleAutoRenewal: {
        name: "Handle Auto Renewal",
        status: StepStatus.NOT_INITIATED,
    },
    InvoiceCreation: {
        name: "Invoice Creation",
        status: StepStatus.NOT_INITIATED,
    },
    InvoiceMetadataUpdate: {
        name: "Invoice Metadata Update",
        status: StepStatus.NOT_INITIATED,
    },
    InvoiceItemDescriptionUpdate: {
        name: "Invoice Item Description Update",
        status: StepStatus.NOT_INITIATED,
    },
    SendInvoiceStep: {
        name: "Send Invoice",
        status: StepStatus.NOT_INITIATED,
    },
};
