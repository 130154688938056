import { useInvoiceFormContext } from "../../contexts/InvoiceFormContext";
import { useAuth } from "../../contexts/AuthContext";

export const useFileUpload = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const { fetchWithReauth } = useAuth();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    fileUse: string,
  ) => {
    const files = Array.from(event.target.files || []);
    const uploadPromises = files.map((file) => uploadFile(file, fileUse));
    await Promise.all(uploadPromises);
  };

  const getPresignedUrl = async (
    file: File,
  ): Promise<{ uploadURL: string; fileKey: string }> => {
    const response = await fetchWithReauth("/api/invoices/document-upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fileName: file.name, fileType: file.type }),
    });

    if (!response.ok) {
      throw new Error("Failed to get presigned URL");
    }

    return response.json();
  };

  const uploadFile = async (file: File, fileUse: string) => {
    try {
      setInvoiceFormData((prevData) => ({
        ...prevData,
        uploadedFileKeys: [
          ...(prevData.uploadedFileKeys || []),
          { fileKey: "", fileName: file.name, fileUse, progress: undefined },
        ],
      }));

      const { uploadURL, fileKey } = await getPresignedUrl(file);
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setInvoiceFormData((prevData) => ({
            ...prevData,
            uploadedFileKeys: (prevData.uploadedFileKeys || []).map(
              (uploadedFile) =>
                uploadedFile.fileName === file.name
                  ? { ...uploadedFile, progress }
                  : uploadedFile,
            ),
          }));
        }
      });

      xhr.open("PUT", uploadURL, true);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);

      return new Promise<void>((resolve, reject) => {
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              setInvoiceFormData((prevData) => ({
                ...prevData,
                uploadedFileKeys: (prevData.uploadedFileKeys || []).map(
                  (uploadedFile) =>
                    uploadedFile.fileName === file.name
                      ? { ...uploadedFile, progress: 100, fileKey }
                      : uploadedFile,
                ),
              }));
              resolve();
            } else {
              reject(new Error("Failed to upload file"));
            }
          }
        };
      });
    } catch (error) {
      console.error("Failed to upload file:", error);
    }
  };

  const handleFileRemove = (fileName: string) => {
    setInvoiceFormData((prevData) => ({
      ...prevData,
      uploadedFileKeys: (prevData.uploadedFileKeys || []).filter(
        (file) => file.fileName !== fileName,
      ),
    }));
  };

  return { invoiceFormData, handleFileChange, handleFileRemove };
};
