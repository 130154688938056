"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentTerm = exports.SubscriptionLineType = exports.LineChargeFrequency = exports.Department = exports.SubscriptionTerm = exports.FileType = void 0;
var FileType;
(function (FileType) {
    FileType["BMPIMAGE"] = "BMPIMAGE";
    FileType["CSV"] = "CSV";
    FileType["EXCEL"] = "EXCEL";
    FileType["GIFIMAGE"] = "GIFIMAGE";
    FileType["JPGIMAGE"] = "JPGIMAGE";
    FileType["PDF"] = "PDF";
    FileType["PJPGIMAGE"] = "PJPGIMAGE";
    FileType["PLAINTEXT"] = "PLAINTEXT";
    FileType["PNGIMAGE"] = "PNGIMAGE";
    FileType["RTF"] = "RTF";
    FileType["SVG"] = "SVG";
    FileType["TIFFIMAGE"] = "TIFFIMAGE";
    FileType["WORD"] = "WORD";
})(FileType || (exports.FileType = FileType = {}));
var SubscriptionTerm;
(function (SubscriptionTerm) {
    SubscriptionTerm[SubscriptionTerm["ANNUAL_1_YEAR"] = 1] = "ANNUAL_1_YEAR";
    SubscriptionTerm[SubscriptionTerm["ANNUAL_2_YEARS"] = 2] = "ANNUAL_2_YEARS";
    SubscriptionTerm[SubscriptionTerm["ANNUAL_3_YEARS"] = 3] = "ANNUAL_3_YEARS";
    SubscriptionTerm[SubscriptionTerm["ANNUAL_4_YEARS"] = 100] = "ANNUAL_4_YEARS";
    SubscriptionTerm[SubscriptionTerm["ANNUAL_5_YEARS"] = 46] = "ANNUAL_5_YEARS";
    SubscriptionTerm[SubscriptionTerm["ANNUAL_6_YEARS"] = 97] = "ANNUAL_6_YEARS";
    SubscriptionTerm[SubscriptionTerm["ANNUAL_10_YEARS"] = 77] = "ANNUAL_10_YEARS";
    SubscriptionTerm[SubscriptionTerm["CUSTOM_TERM"] = -102] = "CUSTOM_TERM";
})(SubscriptionTerm || (exports.SubscriptionTerm = SubscriptionTerm = {}));
var Department;
(function (Department) {
    Department["SALES"] = "2";
    Department["PS"] = "8";
})(Department || (exports.Department = Department = {}));
var LineChargeFrequency;
(function (LineChargeFrequency) {
    LineChargeFrequency["ANNUALLY"] = "ANNUALLY";
    LineChargeFrequency["MONTHLY"] = "MONTHLY";
    LineChargeFrequency["ONE_TIME"] = "ONE_TIME";
})(LineChargeFrequency || (exports.LineChargeFrequency = LineChargeFrequency = {}));
var SubscriptionLineType;
(function (SubscriptionLineType) {
    SubscriptionLineType["ONE_TIME"] = "1";
    SubscriptionLineType["RECURRING"] = "2";
    SubscriptionLineType["USAGE"] = "3";
})(SubscriptionLineType || (exports.SubscriptionLineType = SubscriptionLineType = {}));
var PaymentTerm;
(function (PaymentTerm) {
    PaymentTerm["DUE_ON_RECEIPT"] = "29";
    PaymentTerm["NET_15"] = "1";
    PaymentTerm["NET_30"] = "2";
    PaymentTerm["NET_45"] = "7";
    PaymentTerm["NET_60"] = "3";
    PaymentTerm["NET_75"] = "18";
    PaymentTerm["NET_90"] = "22";
})(PaymentTerm || (exports.PaymentTerm = PaymentTerm = {}));
