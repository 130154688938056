"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const oauth_1 = require("./oauth");
class NetSuiteAPIClient {
    subdomain;
    oauthParams;
    isProd;
    constructor(subdomain, oauthParams) {
        this.subdomain = subdomain;
        this.oauthParams = oauthParams;
        this.isProd = !subdomain.includes("sb2");
    }
    async request(endpoint, method, data, // Define the data type more specifically
    isRestlet) {
        // Construct the base URL based on whether it's a Restlet or not
        const baseUrl = isRestlet
            ? `https://${this.subdomain}.restlets.api.netsuite.com`
            : `https://${this.subdomain}.suitetalk.api.netsuite.com`;
        // Check if method is GET and append data as query params
        let url = `${baseUrl}${endpoint}`;
        if (method.toUpperCase() === "GET" && data) {
            const queryParams = new URLSearchParams(data).toString();
            // Check if the endpoint already contains a '?'
            url += endpoint.includes("?") ? `&${queryParams}` : `?${queryParams}`;
        }
        this.oauthParams.httpMethod = method;
        this.oauthParams.url = url;
        const authorizationHeader = (0, oauth_1.generateOAuthHeader)(this.oauthParams);
        // Prepare request options, excluding body for GET requests
        const options = {
            method,
            headers: {
                "Content-Type": "application/json",
                Authorization: authorizationHeader,
                Prefer: "transient",
            },
            body: method.toUpperCase() !== "GET" && data
                ? JSON.stringify(data)
                : undefined,
        };
        try {
            const response = await fetch(url, options);
            if (response.status === 204) {
                const id = response.headers.get("Location")?.split("/").pop();
                return id ? { status: 204, id } : { status: 204 };
            }
            if (!response.ok) {
                const buffer = await response.arrayBuffer();
                const decoder = new TextDecoder("utf-8");
                const responseBody = decoder.decode(buffer);
                console.error("Response Body:", responseBody);
                throw new Error(`HTTP error! Status: ${response.status}:\n\n${responseBody}`);
            }
            return response.json();
        }
        catch (error) {
            throw new Error(`HTTP error! Status: ${error instanceof Error ? error.message : "Unknown error"}.`);
        }
    }
    async get(endpoint, data) {
        return this.request(endpoint, "GET", data);
    }
    async post(endpoint, data) {
        return this.request(endpoint, "POST", data);
    }
    async patch(endpoint, data) {
        return this.request(endpoint, "PATCH", data);
    }
    async suiteql(query) {
        return this.request("/services/rest/query/v1/suiteql", "POST", {
            q: query,
        });
    }
    async restlet(id, deployId, method, data) {
        const endpoint = `/app/site/hosting/restlet.nl?script=${id}&deploy=${deployId}`;
        return this.request(endpoint, method, data, true);
    }
}
exports.default = NetSuiteAPIClient;
