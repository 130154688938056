import React from "react";
import { Typography, Box } from "@mui/material";

const TermDetailsInstructions: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Term Details Instructions
      </Typography>
      <Typography variant="body2" paragraph>
        First, ensure that the auto-populated fields are correct and correct if
        need be. Right now, the dates and term length are populated from the
        quote and the payment terms are populated from the document review.
      </Typography>
      <Typography variant="body2" paragraph>
        Enter any remaining fields such as the department (Sales for New
        Business and PS for Professional Services) and whether you'd like the
        subscription to initiate auto-renewal.
      </Typography>
    </Box>
  );
};

export default TermDetailsInstructions;
