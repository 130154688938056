import { PaymentTerm } from "models/netsuite";

export const paymentTerms = [
  { label: "100% Due Upon Signature", value: PaymentTerm.DUE_ON_RECEIPT },
  { label: "Net 15", value: PaymentTerm.NET_15 },
  { label: "Net 30", value: PaymentTerm.NET_30 },
  { label: "Net 45", value: PaymentTerm.NET_45 },
  { label: "Net 60", value: PaymentTerm.NET_60 },
  { label: "Net 75", value: PaymentTerm.NET_75 },
  { label: "Net 90", value: PaymentTerm.NET_90 },
];
