import React from "react";
import { Typography, Box } from "@mui/material";

const AdditionalDataInstructions: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Additional Data Instructions
      </Typography>
      <Typography variant="body2" paragraph>
        Here you can enter additional information such as a Customer PO number,
        if they require one, or additional recipients of the invoice, such as
        yourself or your BU Business team.
      </Typography>
      <Typography variant="body2" paragraph>
        Please verify the accuracy of the PO number provided and be sure to
        provide a PO number if the customer requires one.
      </Typography>
    </Box>
  );
};

export default AdditionalDataInstructions;
