import React from "react";
import { Typography, Box } from "@mui/material";

const QuoteLookupInstructions: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Quote Lookup Instructions
      </Typography>
      <Typography variant="body2" paragraph>
        If you have quoted this customer for the charges for this invoice, you
        can look up the quote and pre-load details like the term length, the
        class, the items, and the prices for those items.
      </Typography>
      <Typography variant="body2" paragraph>
        Simply type the name of the customer's company in the Customer field and
        select the appropriate option from the dropdown.
      </Typography>
      <Typography variant="body2" paragraph>
        The four most recent quotes for the customer will show automatically
        below. Find the most relevant one and click to load the details. Please
        wait for confirmation, as it can take up to thirty seconds to load.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        What if the charges I want to invoice were not quoted?
      </Typography>
      <Typography variant="body2" paragraph>
        That's okay! Just click skip for this step, and you will have to
        manually enter more details in the following steps.
      </Typography>
    </Box>
  );
};

export default QuoteLookupInstructions;
