import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";
import { currencySymbolMap } from "../options/currency";

interface Quote {
  id: string;
  trandate: string;
  entity: string;
  currency: string;
  startdate: string;
  enddate: string;
  pretaxTotal: string;
  title: string;
  lineItems: { itemType: string; itemId: string }[];
}

interface QuoteListProps {
  quotes: Quote[];
  onQuoteClick: (quoteId: string) => void;
}

const QuoteList: React.FC<QuoteListProps> = ({ quotes, onQuoteClick }) => {
  const currTotal = (total: string, currency: string) => {
    const symbol = currencySymbolMap[currency];
    return symbol ? symbol + " " + total : total.toString();
  };

  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        {quotes.map((quote) => (
          <Grid item key={quote.id} xs={12} md={6}>
            <Card
              sx={{
                height: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflowY: "auto",
              }}
            >
              <CardContent>
                <Typography variant="h6">
                  {quote.title
                    ? quote.title
                    : `Quote - ${quote.trandate} - ${currTotal(
                        quote.pretaxTotal,
                        quote.currency,
                      )}`}
                </Typography>
                <Typography variant="body2">Date: {quote.trandate}</Typography>
                <Typography variant="body2">
                  Total (Pre-Tax):{" "}
                  {currTotal(quote.pretaxTotal, quote.currency)}
                </Typography>
                <Typography variant="subtitle2">Line Items:</Typography>
                <ul>
                  {quote.lineItems.map((item, index) => (
                    <li key={index}>
                      <Typography variant="body2">{item.itemId}</Typography>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onQuoteClick(quote.id)}
                sx={{ m: 4 }}
              >
                Load Quote
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QuoteList;
