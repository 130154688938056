import React, { useEffect } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useInvoiceFormContext } from "../../../contexts/InvoiceFormContext";
import { billingFrequencies } from "./options/billingAccountBillingFrequency";
import { subscriptionTerms } from "./options/subscriptionTerm";
import { paymentTerms } from "./options/paymentTerms";
import { departments } from "./options/department";
import { SubscriptionTerm } from "models/netsuite";

const termYearMap = {
  [SubscriptionTerm.ANNUAL_1_YEAR]: 1,
  [SubscriptionTerm.ANNUAL_2_YEARS]: 2,
  [SubscriptionTerm.ANNUAL_3_YEARS]: 3,
  [SubscriptionTerm.ANNUAL_4_YEARS]: 4,
  [SubscriptionTerm.ANNUAL_5_YEARS]: 5,
  [SubscriptionTerm.ANNUAL_6_YEARS]: 6,
  [SubscriptionTerm.ANNUAL_10_YEARS]: 10,
};

const TermDetails: React.FC = () => {
  const { invoiceFormData, setInvoiceFormData, setCanAdvance, setBlockReason } =
    useInvoiceFormContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInvoiceFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const requiredFields = [
      { name: "Department", value: invoiceFormData.departmentId },
      { name: "Billing Frequency", value: invoiceFormData.billingFrequency },
      { name: "Subscription Term", value: invoiceFormData.initialTerm },
      { name: "Payment Terms", value: invoiceFormData.paymentTerms },
      { name: "Start Date", value: invoiceFormData.startDate },
      { name: "End Date", value: invoiceFormData.endDate },
    ];

    const emptyFields = requiredFields.filter((field) => !field.value);
    if (emptyFields.length > 0) {
      setCanAdvance(false);
      setBlockReason(
        `The following fields are required: ${emptyFields
          .map((field) => field.name)
          .join(", ")}`
      );
    } else {
      setCanAdvance(true);
      setBlockReason("");
    }
  }, [
    invoiceFormData.departmentId,
    invoiceFormData.billingFrequency,
    invoiceFormData.initialTerm,
    invoiceFormData.paymentTerms,
    invoiceFormData.startDate,
    invoiceFormData.endDate,
    setCanAdvance,
    setBlockReason,
  ]);

  useEffect(() => {
    if (
      invoiceFormData.initialTerm !== SubscriptionTerm.CUSTOM_TERM &&
      invoiceFormData.startDate
    ) {
      const yearsToAdd = termYearMap[invoiceFormData.initialTerm] || 1;
      let startDate, endDate;
      try {
        startDate = new Date(invoiceFormData.startDate);
        endDate = new Date(startDate);
      } catch (e) {
        console.error("Error parsing date", e);
        return;
      }

      endDate.setFullYear(startDate.getFullYear() + yearsToAdd);

      // Correct for edge case where startDate is the first of the month
      if (startDate.getDate() === 1) {
        endDate.setDate(0); // This will set to last day of previous month
      } else {
        endDate.setDate(startDate.getDate() - 1);
      }

      let formattedEndDate;
      try {
        formattedEndDate = endDate.toISOString().split("T")[0];
      } catch (e) {
        console.error("Error formatting date", e);
        return;
      }

      setInvoiceFormData((prevData) => ({
        ...prevData,
        endDate: formattedEndDate,
      }));
    }
  }, [
    invoiceFormData.initialTerm,
    invoiceFormData.startDate,
    setInvoiceFormData,
  ]);

  return (
    <Box>
      <TextField
        select
        label="Department"
        name="departmentId"
        value={invoiceFormData.departmentId}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {departments.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Billing Frequency"
        name="billingFrequency"
        value={invoiceFormData.billingFrequency}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {billingFrequencies.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Subscription Term"
        name="initialTerm"
        type="text"
        value={invoiceFormData.initialTerm}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {subscriptionTerms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Payment Terms"
        name="paymentTerms"
        value={invoiceFormData.paymentTerms}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {paymentTerms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={invoiceFormData.startDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            name="endDate"
            type="date"
            value={invoiceFormData.endDate}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            disabled={
              invoiceFormData.initialTerm !== SubscriptionTerm.CUSTOM_TERM
            }
          />
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={invoiceFormData.autorenewal}
            onChange={(e) =>
              setInvoiceFormData((prevData) => ({
                ...prevData,
                autorenewal: e.target.checked,
              }))
            }
            name="autorenewal"
            color="primary"
          />
        }
        label="Auto Renewal?"
      />
    </Box>
  );
};

export default TermDetails;
