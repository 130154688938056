// InputDetails.tsx
import React, { useState } from "react";
import { Box, Typography, Button, Collapse } from "@mui/material";
import { PsNewBusinessInvoiceRequest } from "models/invoice";

interface InputDetailsProps {
  input: PsNewBusinessInvoiceRequest["input"];
}

const InputDetails: React.FC<InputDetailsProps> = ({ input }) => {
  const [showJson, setShowJson] = useState(false);

  return (
    <Box>
      <Button onClick={() => setShowJson(!showJson)}>
        {showJson ? "Hide Input JSON" : "Show Input JSON"}
      </Button>
      <Collapse in={showJson}>
        <Box mt={2} p={2} bgcolor="#f5f5f5" borderRadius={2}>
          <Typography variant="body2" component="pre">
            {JSON.stringify(input, null, 2)}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default InputDetails;
