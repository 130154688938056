import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { UserRole } from "models/user";

interface NewUserFormProps {
  onCreate: (email: string, role: UserRole) => void;
}

const NewUserForm: React.FC<NewUserFormProps> = ({ onCreate }) => {
  const [newUserEmail, setNewUserEmail] = useState<string>("");
  const [newUserRole, setNewUserRole] = useState<UserRole>(UserRole.REQUESTER);

  const handleCreateUser = () => {
    onCreate(newUserEmail, newUserRole);
    setNewUserEmail("");
    setNewUserRole(UserRole.REQUESTER);
  };

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        Create New User
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <TextField
          label="Email"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          fullWidth
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel>Role</InputLabel>
          <Select
            value={newUserRole}
            onChange={(e) => setNewUserRole(e.target.value as UserRole)}
            label="Role"
          >
            {Object.values(UserRole).map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleCreateUser}>
          Create
        </Button>
      </Box>
    </Paper>
  );
};

export default NewUserForm;
