import React, { createContext, useState, useContext, useEffect } from "react";
import { User, Permission } from "models/user";

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  permissions: Permission[];
  fetchWithReauth: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  login: (user: User) => void;
  logout: () => void;
}

const defaultAuthContextValue: AuthContextType = {
  user: null,
  isAuthenticated: false,
  permissions: [],
  loading: true,
  fetchWithReauth: async (input: RequestInfo, init?: RequestInit) => fetch(input, init),
  login: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultAuthContextValue);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await fetchWithReauth("/api/user");
        if (response.ok) {
          const data: User = await response.json();
          setUser(data);
          setIsAuthenticated(true);
          setPermissions(data.policies[0].permissions);
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        setIsAuthenticated(false);
        setUser(null);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const login = (user: User) => {
    setUser(user);
    setIsAuthenticated(true);
    setPermissions(user.policies[0].permissions);
  };

  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
  };

  const fetchWithReauth = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    const fetchFunc = () => fetch(input, init);
    let response = await fetchFunc();

    if (response.status === 401 || response.status === 403) {
      const refreshResp = await fetch("/api/refresh");
      if (refreshResp.ok) {
        response = await fetchFunc();
      } else {
        logout();
      }
    }

    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        permissions,
        loading,
        login,
        logout,
        fetchWithReauth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
