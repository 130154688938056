import { useState } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { useAuth } from "src/contexts/AuthContext";

interface CreatePoNumberReissueResult {
  transactionId: string;
  status: "SUCCESS" | "FAIL";
  message?: string;
}

export const useCreatePoNumberReissueRequest = () => {
  const [result, setResult] = useState<CreatePoNumberReissueResult | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchWithReauth } = useAuth();

  const createPoNumberReissueRequest = async (
    transactionId: string,
    poNumber: string,
    fileKey?: string
  ) => {
    setLoading(true);
    try {
      const response = await fetchWithReauth(`${API_URL}/invoices/po-reissue`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ transactionId, poNumber, fileKey }),
      });
      const data = await checkResponse(response);
      setResult(data);
    } catch (error) {
      console.error("Error creating PO number reissue request:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    result,
    loading,
    createPoNumberReissueRequest,
  };
};
