import React from "react";
import {
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { User, UserRole, Permission } from "models/user";

interface UserListProps {
  users: User[];
  onDelete: (email: string) => void;
  onUpdatePolicy: (email: string, role: UserRole) => void;
  onAddPermission: (email: string, permission: Permission) => void;
  onRemovePermission: (email: string, permission: Permission) => void;
  newPermission: Permission;
  setNewPermission: (permission: Permission) => void;
}

const UserList: React.FC<UserListProps> = ({
  users,
  onDelete,
  onUpdatePolicy,
  onAddPermission,
  onRemovePermission,
  newPermission,
  setNewPermission,
}) => {
  return (
    <Box>
      {users.map((user) => (
        <Box key={user.email}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 2,
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {user.email}
            </Typography>
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
              <InputLabel>Role</InputLabel>
              <Select
                value={user.policies[0].role}
                onChange={(e) =>
                  onUpdatePolicy(user.email, e.target.value as UserRole)
                }
                label="Role"
              >
                {Object.values(UserRole).map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {user.policies[0].permissions.map((permission: Permission) => (
                <Chip
                  key={permission}
                  label={permission}
                  onDelete={() => onRemovePermission(user.email, permission)}
                />
              ))}
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel>Add Permission</InputLabel>
                <Select
                  value={newPermission}
                  onChange={(e) =>
                    setNewPermission(e.target.value as Permission)
                  }
                  label="Add Permission"
                >
                  {Object.values(Permission).map((perm) => (
                    <MenuItem key={perm} value={perm}>
                      {perm}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onAddPermission(user.email, newPermission)}
              >
                Add
              </Button>
            </Box>
            <IconButton
              onClick={() => onDelete(user.email)}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default UserList;
