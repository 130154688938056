import React, { createContext, useContext, useState, ReactNode } from "react";
import { SubscriptionTerm, PaymentTerm } from "models/netsuite";
import { InvoiceFormData } from "models/invoice";

interface InvoiceFormContextProps {
  invoiceFormData: InvoiceFormData;
  setInvoiceFormData: React.Dispatch<React.SetStateAction<InvoiceFormData>>;
  canAdvance: boolean;
  setCanAdvance: React.Dispatch<React.SetStateAction<boolean>>;
  blockReason: string;
  setBlockReason: React.Dispatch<React.SetStateAction<string>>;
}

const initialFormState: InvoiceFormData = {
  autorenewal: false,
  customerId: "",
  customerName: "",
  classId: "",
  className: "",
  departmentId: "",
  subscriptionPlanId: "",
  subscriptionPlanName: "",
  currencyId: 1,
  billingFrequency: "Annually",
  startDate: "",
  endDate: "",
  initialTerm: SubscriptionTerm.CUSTOM_TERM,
  items: [],
  paymentTerms: PaymentTerm.DUE_ON_RECEIPT,
  contactEmail: "",
  shippingAddress: "",
  billingAddress: "",
  additionalRecipients: [],
  poNumber: "",
};

const InvoiceFormContext = createContext<InvoiceFormContextProps | undefined>(
  undefined,
);

export const useInvoiceFormContext = () => {
  const context = useContext(InvoiceFormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const InvoiceFormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [invoiceFormData, setInvoiceFormData] = useState(initialFormState);
  const [canAdvance, setCanAdvance] = useState(true);
  const [blockReason, setBlockReason] = useState("");

  return (
    <InvoiceFormContext.Provider
      value={{
        invoiceFormData,
        setInvoiceFormData,
        canAdvance,
        setCanAdvance,
        blockReason,
        setBlockReason,
      }}
    >
      {children}
    </InvoiceFormContext.Provider>
  );
};
