import { useState, useEffect } from "react";
import { User, UserRole, Policy, Permission } from "models/user";
import { useAuth } from "../contexts/AuthContext";

export const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchWithReauth } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchWithReauth("/api/users", { method: "GET" });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (newUser: User) => {
    try {
      const response = await fetchWithReauth("/api/users", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser),
      });
      if (response.ok) {
        setUsers((prevUsers) => [...prevUsers, newUser]);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const deleteUser = async (email: string) => {
    try {
      const response = await fetchWithReauth(`/api/users/${email}`, {
        method: "DELETE",
      });
      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.email !== email),
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const updateUserPolicy = async (email: string, role: UserRole) => {
    const user = users.find((user) => user.email === email);
    if (!user) return;

    const updatedPolicies: Policy[] = [
      { role, permissions: user.policies[0].permissions },
    ];
    try {
      const response = await fetchWithReauth(`/api/users/${email}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ policies: updatedPolicies }),
      });
      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.email === email
              ? { ...user, policies: updatedPolicies }
              : user,
          ),
        );
      }
    } catch (error) {
      console.error("Error updating user policy:", error);
    }
  };

  const addPermission = async (email: string, permission: Permission) => {
    const user = users.find((user) => user.email === email);
    if (!user) return;

    const updatedPermissions = [...user.policies[0].permissions, permission];
    const updatedPolicies: Policy[] = [
      { ...user.policies[0], permissions: updatedPermissions },
    ];
    try {
      const response = await fetchWithReauth(`/api/users/${email}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ policies: updatedPolicies }),
      });
      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.email === email
              ? { ...user, policies: updatedPolicies }
              : user,
          ),
        );
      }
    } catch (error) {
      console.error("Error adding permission:", error);
    }
  };

  const removePermission = async (email: string, permission: Permission) => {
    const user = users.find((user) => user.email === email);
    if (!user) return;

    const updatedPermissions = user.policies[0].permissions.filter(
      (perm) => perm !== permission,
    );
    const updatedPolicies: Policy[] = [
      { ...user.policies[0], permissions: updatedPermissions },
    ];
    try {
      const response = await fetchWithReauth(`/api/users/${email}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ policies: updatedPolicies }),
      });
      if (response.ok) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.email === email
              ? { ...user, policies: updatedPolicies }
              : user,
          ),
        );
      }
    } catch (error) {
      console.error("Error removing permission:", error);
    }
  };

  return {
    users,
    loading,
    fetchUsers,
    createUser,
    deleteUser,
    updateUserPolicy,
    addPermission,
    removePermission,
  };
};
