import { useState, useEffect } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { ExceptionInvoiceRequest } from "models/exception-invoice";
import { useAuth } from "src/contexts/AuthContext";

export const useExceptionInvoiceRequest = (requestId: string) => {
  const [exceptionInvoiceRequest, setExceptionInvoiceRequest] =
    useState<ExceptionInvoiceRequest | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { fetchWithReauth } = useAuth();

  const fetchExceptionInvoiceRequest = async (requestId: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchWithReauth(
        `${API_URL}/invoices/exception-invoices/${requestId}`,
        { method: "GET" }
      );
      const data = await checkResponse(response);
      setExceptionInvoiceRequest(data);
    } catch (error) {
      console.error("Error fetching exception invoice request:", error);
      setError("Failed to fetch the exception invoice request.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (requestId) {
      fetchExceptionInvoiceRequest(requestId);
    }
  }, [requestId]);

  return {
    exceptionInvoiceRequest,
    loading,
    error,
    refetch: () => fetchExceptionInvoiceRequest(requestId),
  };
};
