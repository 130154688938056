import React from "react";
import { Chip, SxProps, Theme } from "@mui/material";

interface StatusChipProps {
  status: string;
  sx?: SxProps<Theme>;
}

const StatusChip: React.FC<StatusChipProps> = ({ status, sx }) => {
  const getStatusColor = (status: string) => {
    switch (status) {
      case "COMPLETED":
        return "success";
      case "FAILED":
        return "error";
      case "IN_PROGRESS":
        return "info";
      default:
        return "default";
    }
  };

  const formatStatus = (status: string) => {
    return status
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b(\w)/g, (char) => char.toUpperCase());
  };

  return (
    <Chip label={formatStatus(status)} color={getStatusColor(status)} sx={sx} />
  );
};

export default StatusChip;
