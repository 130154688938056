import React from "react";
import { Typography, Box } from "@mui/material";

const DocumentCheckInstructions: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Document Check Instructions
      </Typography>
      <Typography variant="body2" paragraph>
        Here you can upload the required documents (PDFs, PNGs, JPEGs) to ensure
        that an invoice can be generated. Our AI review will also attempt to
        populate additional fields in the later steps based on information
        identified here, such as customer address updates and contact email.
      </Typography>
      <Typography variant="body2" paragraph>
        You can upload the following documents:
      </Typography>
      <Typography variant="body2" paragraph>
        1. <strong>Signed Quote:</strong> If you have a signed quote that is
        both <strong>signed by the customer</strong> and{" "}
        <strong>counter-signed</strong> by a Finance team member. You don't need
        any other documents to invoice a signed quote.
      </Typography>
      <Typography variant="body2" paragraph>
        2. <strong>Purchase Order:</strong> You can only invoice from a purchase
        order if you have <strong>explicit approval</strong> from the Finance
        team, which should be uploaded in a separate document.
      </Typography>
      <Typography variant="body2" paragraph>
        3. <strong>Approval Documents:</strong> These would include any
        documents that verify that the Finance team has approved invoicing from
        a Purchase Order or other type of contractual document. These could
        include screenshots and images of emails or prior O2C comments from the
        Finance team.
      </Typography>
      <Typography variant="body2" paragraph>
        4. <strong>Additional Documents:</strong> If you have additional
        documents that you would like to include as relevant for this invoice
        case, upload them here. Their attachment will be noted to our AI
        Reviewer and they will be added to Netsuite for documentations but they
        will not be specifically reviewed by the AI for invoice generation.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        When I click the QC button, it stops quickly and doesn't finish.
      </Typography>
      <Typography variant="body2" paragraph>
        This can sometimes occur when the files were still uploading when the
        button was pressed. Please try again, and if it still fails, reach out
        for technical support.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        It says I don't have approval to invoice and I really need this invoice
        sent.
      </Typography>
      <Typography variant="body2" paragraph>
        Please create an O2C case to invoice now. We are eager to improve the
        approval process to handle all cases, so please mention in the O2C case
        the response you received so we can make the process easier for your
        next invoice.
      </Typography>
    </Box>
  );
};

export default DocumentCheckInstructions;
