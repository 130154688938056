import { useState, useEffect } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { PoNumberReissueRequest } from "models/po-reissue"; // Assuming you have this model
import { useAuth } from "src/contexts/AuthContext";

export const usePoNumberReissueRequests = () => {
  const [poNumberReissueRequests, setPoNumberReissueRequests] = useState<
    PoNumberReissueRequest[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchWithReauth } = useAuth();

  const fetchPoNumberReissueRequestsByMonth = async (yearMonth?: string) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      if (yearMonth) {
        queryParams.append("yearMonth", yearMonth);
      }

      const response = await fetchWithReauth(
        `${API_URL}/invoices/po-reissue?${queryParams.toString()}`,
        { method: "GET" }
      );
      if (response.status === 404) {
        setPoNumberReissueRequests([]);
      } else {
        const data = await checkResponse(response);
        setPoNumberReissueRequests(data.items);
      }
    } catch (error) {
      console.error("Error fetching PO Number reissue requests:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    poNumberReissueRequests,
    loading,
    fetchPoNumberReissueRequestsByMonth,
  };
};
