import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridColTypeDef,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useInvoiceFormContext } from "../../../../contexts/InvoiceFormContext";
import { Item } from "models/invoice";
import { SubscriptionLineType, SubscriptionTerm } from "models/netsuite";
import { ItemCellAutocomplete } from "./ItemCellAutocomplete";
import { LineChargeFrequency } from "models/netsuite";
import EditTextarea from "./EditTextareaGrid";
import useSubscriptionPlanLines from "src/hooks/invoices/useSubscriptionPlanLines";

function isKeyboardEvent(event: any): event is React.KeyboardEvent {
  return !!event.key;
}

const multilineColumn: GridColTypeDef = {
  type: "string",
  renderEditCell: (params) => <EditTextarea {...params} />,
};

const SubscriptionItemGrid: React.FC = () => {
  const { invoiceFormData, setInvoiceFormData } = useInvoiceFormContext();
  const { isLoadingPlanItems } = useSubscriptionPlanLines(
    invoiceFormData.subscriptionPlanId
  );
  const [rows, setRows] = useState<GridRowsProp>(
    invoiceFormData.items.map((item, index) => ({
      id: index,
      ...item,
    }))
  );
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  useEffect(() => {
    setRows(
      invoiceFormData.items.map((item, index) => ({
        id: index,
        ...item,
      }))
    );
  }, [invoiceFormData]); // }, [invoiceFormData.items]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (
      params.reason &&
      params.reason !== GridRowEditStopReasons.enterKeyDown
    ) {
      return;
    }
    if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    setInvoiceFormData((prevData) => ({
      ...prevData,
      items: updatedRows.map((row) => {
        const { id, ...item } = row;
        return item;
      }) as Item[],
    }));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      setInvoiceFormData((prevData) => ({
        ...prevData,
        items: updatedRows.map((row) => {
          const { id, ...item } = row;
          return item;
        }) as Item[],
      }));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    // To get aroundn messing itemid updates.
    const updatedRow = newRow.itemId.id
      ? {
          ...newRow,
          itemName: newRow.itemId.label,
          itemId: newRow.itemId.id,
          isNew: false,
        }
      : {
          ...newRow,
          isNew: false,
        };
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.id === newRow.id ? updatedRow : row
      );

      setInvoiceFormData((prevData) => {
        const updatedItems = updatedRows.map((row) => {
          const { id, ...item } = row as GridRowModel;
          // same here...
          if (item.itemId?.id) {
            item.itemName = item.itemId.label;
            item.itemId = item.itemId.id;
          }
          return item;
        }) as Item[];

        return {
          ...prevData,
          items: updatedItems,
        };
      });

      return updatedRows;
    });

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddRow = () => {
    const id = invoiceFormData.items.length;
    const newRow = {
      id,
      include: true,
      itemId: "",
      description: "",
      quantity: 1,
      totalValue: 0,
      chargeFrequency: LineChargeFrequency.ONE_TIME,
      isNew: true,
    };
    setRows((oldRows) => [...oldRows, newRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "itemId" },
    }));
  };

  const getValueOverTerm = (
    lineValue: number,
    chargeFreq: LineChargeFrequency,
    term: SubscriptionTerm
  ) => {
    let multiplier = 1;

    const isOneTime = chargeFreq === LineChargeFrequency.ONE_TIME;
    const isMonthly = chargeFreq === LineChargeFrequency.MONTHLY;

    if (isOneTime) return lineValue;

    // Improve this later to work with start and end dates
    if (term === SubscriptionTerm.CUSTOM_TERM) return lineValue;

    if (term === SubscriptionTerm.ANNUAL_1_YEAR) multiplier = 1;
    if (term === SubscriptionTerm.ANNUAL_2_YEARS) multiplier = 2;
    if (term === SubscriptionTerm.ANNUAL_3_YEARS) multiplier = 3;
    if (term === SubscriptionTerm.ANNUAL_4_YEARS) multiplier = 4;
    if (term === SubscriptionTerm.ANNUAL_5_YEARS) multiplier = 5;
    if (term === SubscriptionTerm.ANNUAL_6_YEARS) multiplier = 6;
    if (term === SubscriptionTerm.ANNUAL_10_YEARS) multiplier = 10;

    if (isMonthly) multiplier = multiplier * 12;

    return lineValue * multiplier;
  };

  const columns: GridColDef[] = [
    {
      field: "include",
      headerName: "Include?",
      width: 80,
      editable: true,
      type: "boolean",
    },
    {
      field: "itemId",
      headerName: "Item",
      width: 150,
      editable: true,
      valueFormatter: (value, row) => row.itemName || "",
      renderEditCell: (params) => (
        <ItemCellAutocomplete
          params={params}
          option={
            params.row.itemId?.id
              ? { id: params.row.itemId.id, label: params.row.itemId.label }
              : params.row.itemId
              ? { id: params.row.itemId, label: params.row.itemName }
              : null
          }
        />
      ),
    },
    {
      field: "description",
      headerName: "Line Memo",
      flex: 1,
      minWidth: 200,
      editable: true,
      ...multilineColumn,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "totalValue",
      headerName: "Value",
      type: "number",
      width: 100,
      editable: true,
    },
    {
      field: "subscriptionLineType",
      headerName: "Type",
      width: 130,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        SubscriptionLineType.ONE_TIME,
        SubscriptionLineType.RECURRING,
        SubscriptionLineType.USAGE,
      ],
      getOptionLabel: (option) => {
        switch (option) {
          case SubscriptionLineType.ONE_TIME:
            return "One Time";
          case SubscriptionLineType.RECURRING:
            return "Recurring";
          case SubscriptionLineType.USAGE:
            return "Usage";
          default:
            return "";
        }
      },
    },
    {
      field: "chargeFrequency",
      headerName: "Frequency",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: ["ONE_TIME", "MONTHLY", "ANNUALLY"],
      getOptionLabel: (option) => {
        switch (option) {
          case "ONE_TIME":
            return "One Time";
          case "MONTHLY":
            return "Monthly";
          case "ANNUALLY":
            return "Annually";
          default:
            return "";
        }
      },
    },
    {
      field: "totalValueOverTerm",
      type: "number",
      headerName: "Total Over Term",
      width: 140,
      valueGetter: (_, row) => {
        const value = getValueOverTerm(
          row.totalValue,
          row.chargeFrequency,
          invoiceFormData.initialTerm
        );
        return value;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 80,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <Tooltip
            title={row.required ? "Item is required for subscription plan" : ""}
          >
            <span>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={handleDeleteClick(id)}
                color="inherit"
                disabled={row.required}
              />
            </span>
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 380,
        width: "100%",
        "& .actions": { color: "text.secondary" },
        "& .textPrimary": { color: "text.primary" },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        isCellEditable={(params) =>
          params.field !== "include" || !params.row.required
        }
        loading={isLoadingPlanItems}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <Button
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddRow}
                disabled={isLoadingPlanItems}
              >
                Add Item
              </Button>
            </GridToolbarContainer>
          ),
        }}
      />
    </Box>
  );
};

export default SubscriptionItemGrid;
