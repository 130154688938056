import React from "react";
import { Chip, SxProps, Theme } from "@mui/material";
import { InvoiceRequestType } from "models/invoice";

const TypeChip: React.FC<{ type: InvoiceRequestType; sx?: SxProps<Theme> }> = ({
  type,
  sx,
}) => {
  const getType = (type: InvoiceRequestType) => {
    switch (type) {
      case InvoiceRequestType.PS_NEW_BUSINESS:
        return "PS / New Business";
      case InvoiceRequestType.RENEWAL:
        return "Renewal";
      case InvoiceRequestType.USAGE:
        return "Usage";
      default:
        return type;
    }
  };

  return <Chip label={getType(type)} sx={sx} />;
};

export default TypeChip;
