import React from "react";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface FileItemProps {
  file: {
    fileName: string;
    fileUse: string;
    progress?: number;
  };
  onRemove: (fileName: string) => void;
}

const FileItem: React.FC<FileItemProps> = ({ file, onRemove }) => (
  <Paper
    sx={{
      display: "flex",
      alignItems: "center",
      mt: 1,
      p: 1,
      bgcolor: file.progress === 100 ? "#d4edda" : "#fff3cd",
      borderRadius: 1,
      boxShadow: 1,
    }}
  >
    <Box sx={{ flexGrow: 1 }}>
      <Typography>{file.fileName}</Typography>
      <Typography variant="body2" color="textSecondary">
        {file.progress === undefined
          ? "Waiting to start upload"
          : `${file.progress.toFixed(2)}%`}
      </Typography>
    </Box>
    {file.progress !== undefined && file.progress < 100 && (
      <CircularProgress size={24} variant="determinate" value={file.progress} />
    )}
    <IconButton onClick={() => onRemove(file.fileName)} aria-label="delete">
      <DeleteIcon />
    </IconButton>
  </Paper>
);

export default FileItem;
