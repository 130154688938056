import { useCallback } from "react";
import _ from "lodash";
import { useAuth } from "../../contexts/AuthContext";

interface Option {
  label: string;
  id: string;
}

const useNetsuiteLookup = (recordType: string) => {
  const { fetchWithReauth } = useAuth();

  const fetchData = useCallback(async (query: string): Promise<Option[]> => {
    const response = await fetchWithReauth(
      `/api/netsuite/lookup?recordType=${recordType}&name=${encodeURIComponent(query)}`
    );
    const data = await response.json();
    return data.map((item: { name: string; id: string }) => ({
      label: item.name,
      id: item.id,
    }));
  }, [fetchWithReauth, recordType]);

  return fetchData;
};

export default useNetsuiteLookup;
