import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Chip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePoNumberReissueRequest } from "src/hooks/po-number-reissue/usePoNumberReissueRequest"; // Use the appropriate hook for PoNumberReissueRequest
import StatusChip from "../general/StatusChip"; // Assuming you have a status chip component

const PoReissue: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);

  const {
    poNumberReissueRequest: requestRecord,
    loading,
    fetchPoNumberReissueRequestById,
  } = usePoNumberReissueRequest();

  const fetchRequestRecord = useCallback(async () => {
    if (id) {
      const lookupId = id.split("-")[1]; // Extract the ID part from 'POREQ-ANBRSU'
      setRefreshing(true);

      try {
        await fetchPoNumberReissueRequestById(lookupId);
      } finally {
        setRefreshing(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchRequestRecord();
  }, []);

  useEffect(() => {
    if (requestRecord?.status === "IN_PROGRESS") {
      const intervalId = setInterval(() => {
        fetchRequestRecord();
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [requestRecord?.status]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!requestRecord) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="body1">
          No PO Number Reissue Request found.
        </Typography>
      </Box>
    );
  }

  const renderOutputDetails = () => {
    return (
      <>
        <Typography variant="body2">
          <strong>Was Sent:</strong>{" "}
          {requestRecord.output.wasSent ? "Yes" : "No"}
        </Typography>
        {requestRecord.output.toList &&
          requestRecord.output.toList.length > 0 && (
            <Typography variant="body2">
              <strong>To List:</strong> {requestRecord.output.toList.join(", ")}
            </Typography>
          )}
        {requestRecord.output.ccList &&
          requestRecord.output.ccList.length > 0 && (
            <Typography variant="body2">
              <strong>CC List:</strong> {requestRecord.output.ccList.join(", ")}
            </Typography>
          )}
        <Typography variant="body2">
          <strong>Needs Upload:</strong>{" "}
          {requestRecord.output.needsUpload ? "Yes" : "No"}
        </Typography>
        {requestRecord.output.uploadWorkUnit && (
          <Typography variant="body2">
            <strong>Upload Work Unit:</strong>{" "}
            <a
              href={`https://devgraph-alp.atlassian.net/browse/${requestRecord.output.uploadWorkUnit}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {requestRecord.output.uploadWorkUnit}
            </a>
          </Typography>
        )}
      </>
    );
  };

  return (
    <Box p={3}>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box display="flex" alignItems="center" mb={3} gap={2}>
          <IconButton onClick={() => navigate("/po-reissues")} size="small">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4">INVPO-{requestRecord.requestId}</Typography>
          <Chip
            label={`Transaction ID: ${requestRecord.input.transactionId}`}
            color="default"
          />
          <StatusChip status={requestRecord.status} />
          {refreshing && <CircularProgress size={24} />}
        </Box>
        <Box mb={3}>
          <Typography variant="h6">Input Details</Typography>
          <Typography variant="body2">
            <strong>Transaction ID:</strong> {requestRecord.input.transactionId}
          </Typography>
          <Typography variant="body2">
            <strong>PO Number:</strong> {requestRecord.input.poNumber}
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Output Details
          </Typography>
          <Box>{renderOutputDetails()}</Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PoReissue;
