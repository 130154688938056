"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateOAuthHeader = void 0;
const crypto_js_1 = require("crypto-js");
function generateOAuthHeader(oauthParams) {
    const nonce = Math.random().toString(36).substring(2);
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const parameters = {
        oauth_consumer_key: oauthParams.consumerKey,
        oauth_nonce: nonce,
        oauth_signature_method: "HMAC-SHA256",
        oauth_timestamp: timestamp,
        oauth_token: oauthParams.tokenKey,
        oauth_version: "1.0",
    };
    const baseString = generateBaseString(oauthParams.httpMethod, oauthParams.url, parameters);
    const signingKey = `${encodeURIComponent(oauthParams.consumerSecret)}&${encodeURIComponent(oauthParams.tokenSecret)}`;
    const signature = crypto_js_1.enc.Base64.stringify((0, crypto_js_1.HmacSHA256)(baseString, signingKey));
    let authorizationHeader = `OAuth realm="${encodeURIComponent(oauthParams.realm)}"`;
    authorizationHeader += `,oauth_token="${encodeURIComponent(parameters.oauth_token)}"`;
    authorizationHeader += `,oauth_consumer_key="${encodeURIComponent(parameters.oauth_consumer_key)}"`;
    authorizationHeader += `,oauth_nonce="${encodeURIComponent(parameters.oauth_nonce)}"`;
    authorizationHeader += `,oauth_timestamp="${encodeURIComponent(parameters.oauth_timestamp)}"`;
    authorizationHeader += `,oauth_signature_method="${encodeURIComponent(parameters.oauth_signature_method)}"`;
    authorizationHeader += `,oauth_version="${encodeURIComponent(parameters.oauth_version)}"`;
    authorizationHeader += `,oauth_signature="${encodeURIComponent(signature)}"`;
    return authorizationHeader;
}
exports.generateOAuthHeader = generateOAuthHeader;
function generateBaseString(httpMethod, url, parameters) {
    const baseUrl = url.split("?")[0];
    const queryParams = new URLSearchParams(url.split("?")[1] || "");
    for (const key of Object.keys(parameters)) {
        queryParams.append(key, parameters[key]);
    }
    const encodedParams = Array.from(queryParams.entries())
        .sort()
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");
    return `${httpMethod.toUpperCase()}&${encodeURIComponent(baseUrl)}&${encodeURIComponent(encodedParams)}`;
}
