import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { InvoiceRequestService } from "../../services/InvoiceRequestService";
import {
  InvoiceRequestType,
  PsNewBusinessInvoiceRequest,
} from "models/invoice";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Link,
  Chip,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InputDetails from "./display-components/InputDetails";
import {
  SubsidiaryCheckStep,
  BillingAccountCreationStep,
  CustomPricingPlansCreationStep,
  SubscriptionCreationStep,
  ContractualDocumentsCreationStep,
  DraftItemsActivationStep,
  SubscriptionActivationStep,
  InvoiceCreationStep,
  InvoiceItemDescriptionUpdateStep,
  InvoiceMetadataUpdateStep,
  HandleAutoRenewalStep,
  SendInvoiceStep,
} from "./display-components/InvoiceOutputSteps";
import { isProd } from "../../util/helpers";
import StatusChip from "../general/StatusChip";
import TypeChip from "./display-components/TypeChip";

const Invoice: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [invoiceRequest, setInvoiceRequest] =
    useState<PsNewBusinessInvoiceRequest | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchInvoiceRequest = useCallback(async () => {
    const lookupId = id?.split("-")?.[1];
    if (lookupId) {
      const invoiceService = new InvoiceRequestService();
      setRefreshing(true); // Set refreshing to true before fetching data
      const startTime = Date.now(); // Record the start time
      try {
        const invoiceData = await invoiceService.getInvoiceRequestBy(lookupId);
        setInvoiceRequest(invoiceData);
      } catch (err) {
        setError("Failed to fetch invoice.");
      } finally {
        const elapsed = Date.now() - startTime;
        const delay = Math.max(0, 2000 - elapsed); // Calculate delay to ensure 2 seconds
        setTimeout(() => {
          setLoading(false);
          setRefreshing(false); // Set refreshing to false after fetching data
        }, delay);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchInvoiceRequest();
  }, [fetchInvoiceRequest]);

  useEffect(() => {
    if (invoiceRequest?.status === "IN_PROGRESS") {
      const intervalId = setInterval(() => {
        fetchInvoiceRequest();
      }, 5000); // Change the interval to 5 seconds
      return () => clearInterval(intervalId);
    }
  }, [invoiceRequest, fetchInvoiceRequest]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const renderOutputStep = (key: string) => {
    switch (key) {
      case "SubsidiaryCheck": {
        const step = invoiceRequest!.output.SubsidiaryCheck;
        return (
          <SubsidiaryCheckStep
            name={step.name}
            status={step.status}
            subsidiaryId={step.subsidiaryId}
          />
        );
      }
      case "BillingAccountCreation": {
        const step = invoiceRequest!.output.BillingAccountCreation;
        return (
          <BillingAccountCreationStep
            name={step.name}
            status={step.status}
            billingAccountId={step.billingAccountId}
          />
        );
      }
      case "CustomPricingPlansCreation": {
        const step = invoiceRequest!.output.CustomPricingPlansCreation;
        return (
          <CustomPricingPlansCreationStep
            name={step.name}
            status={step.status}
            customPricingPlanIds={step.customPricingPlanIds}
          />
        );
      }
      case "SubscriptionCreation": {
        const step = invoiceRequest!.output.SubscriptionCreation;
        return (
          <SubscriptionCreationStep
            name={step.name}
            status={step.status}
            subscriptionId={step.subscriptionId}
          />
        );
      }
      case "ContractualDocumentsCreation": {
        const step = invoiceRequest!.output.ContractualDocumentsCreation;
        return (
          <ContractualDocumentsCreationStep
            name={step.name}
            status={step.status}
            contractualDocumentId={step.contractualDocumentId}
          />
        );
      }
      case "DraftItemsActivation": {
        const step = invoiceRequest!.output.DraftItemsActivation;
        return (
          <DraftItemsActivationStep name={step.name} status={step.status} />
        );
      }
      case "SubscriptionActivation": {
        const step = invoiceRequest!.output.SubscriptionActivation;
        return (
          <SubscriptionActivationStep
            name={step.name}
            status={step.status}
            subscriptionActivationId={step.subscriptionActivationId}
          />
        );
      }
      case "InvoiceCreation": {
        const step = invoiceRequest!.output.InvoiceCreation;
        return (
          <InvoiceCreationStep
            name={step.name}
            status={step.status}
            invoiceId={step.invoiceId}
          />
        );
      }
      case "InvoiceItemDescriptionUpdate": {
        const step = invoiceRequest!.output.InvoiceItemDescriptionUpdate;
        return (
          <InvoiceItemDescriptionUpdateStep
            name={step.name}
            status={step.status}
          />
        );
      }
      case "InvoiceMetadataUpdate": {
        const step = invoiceRequest!.output.InvoiceMetadataUpdate;
        return (
          <InvoiceMetadataUpdateStep name={step.name} status={step.status} />
        );
      }
      case "HandleAutoRenewal": {
        const step = invoiceRequest!.output.HandleAutoRenewal;
        return <HandleAutoRenewalStep name={step.name} status={step.status} />;
      }
      case "SendInvoiceStep": {
        const step = invoiceRequest!.output.SendInvoiceStep;
        return <SendInvoiceStep name={step.name} status={step.status} />;
      }
      default:
        return <></>;
    }
  };

  return (
    <Box p={3}>
      {invoiceRequest ? (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box display="flex" alignItems="center" mb={3} gap={2}>
            <IconButton onClick={() => navigate("/invoices")} size="small">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4">
              INVREQ-{invoiceRequest?.requestId}
            </Typography>
            <Chip label={invoiceRequest?.requester} color="default" />
            <TypeChip type={invoiceRequest.type} />
            <StatusChip status={invoiceRequest.status} />
            {refreshing && <CircularProgress size={24} />}
          </Box>
          {invoiceRequest.invoiceId && (
            <Typography variant="body1" mb={3}>
              Invoice ID:{" "}
              <Link
                href={`https://${
                  isProd ? "4914352" : "4914352-sb2"
                }.app.netsuite.com/app/accounting/transactions/custinvc.nl?id=${
                  invoiceRequest.invoiceId
                }&l=T&whence=`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {invoiceRequest.invoiceId}
              </Link>
            </Typography>
          )}
          <Box>
            <Typography variant="h6">Output Details</Typography>
            <Box display="flex" flexWrap="wrap">
              {Object.keys(invoiceRequest.output).map((key) => (
                <Box key={key} width="280px" p={1}>
                  {renderOutputStep(key)}
                </Box>
              ))}
            </Box>
          </Box>
          {invoiceRequest.status === "FAILED" && (
            <Box
              mb={1}
              p={2}
              borderRadius={2}
              border={1}
              borderColor="error.main"
              bgcolor="#ffdddd"
              color="error.main"
            >
              <Typography variant="body2" sx={{ paddingBottom: "4px" }}>
                <strong>Uh oh!</strong> Sorry that this invoice request has
                failed.
              </Typography>
              <Typography variant="body2" sx={{ paddingBottom: "4px" }}>
                Please raise an <strong>O2C case</strong> with the Quote DS as
                you normally would. Please share the reference number for this
                request.
              </Typography>
              <Typography variant="body2" sx={{ paddingBottom: "4px" }}>
                Our team is regularly reviewing failures to ensure future
                requests are handled effectively in this early stage of
                deployment.
              </Typography>
            </Box>
          )}
          <InputDetails input={invoiceRequest.input} />
        </Paper>
      ) : (
        <Typography variant="body1">No invoice found.</Typography>
      )}
    </Box>
  );
};

export default Invoice;
