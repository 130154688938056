import React from "react";
import { Typography, Box } from "@mui/material";

const CustomerDetailsInstructions: React.FC = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Customer Details Instructions
      </Typography>
      <Typography variant="body2" paragraph>
        First, double check that the auto-populated fields are correct and
        correct if need be.
      </Typography>
      <Typography variant="body2" paragraph>
        Also, be sure that the contact email is entered correctly. This will be
        where the invoice is sent. If the invoice needs to be handled
        differently, other then emailing, you can list your own email here. You
        can also add additional emails in the Additional Information step at the
        end.
      </Typography>
    </Box>
  );
};

export default CustomerDetailsInstructions;
