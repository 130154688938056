import { useState } from "react";
import { useAuth } from "src/contexts/AuthContext";

export const useFileUpload = () => {
  const { fetchWithReauth } = useAuth();
  const [uploadedFiles, setUploadedFiles] = useState<
    { fileKey: string; fileName: string; progress?: number }[]
  >([]);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(event.target.files || []);
    const uploadPromises = files.map((file) => uploadFile(file));
    await Promise.all(uploadPromises);
  };

  const getPresignedUrl = async (
    file: File
  ): Promise<{ uploadURL: string; fileKey: string }> => {
    const response = await fetchWithReauth("/api/upload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fileName: file.name, fileType: file.type }),
    });

    if (!response.ok) {
      throw new Error("Failed to get presigned URL");
    }

    return response.json();
  };

  const uploadFile = async (file: File) => {
    try {
      // Add file to state with empty key and progress 0
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        { fileKey: "", fileName: file.name, progress: 0 },
      ]);

      const { uploadURL, fileKey } = await getPresignedUrl(file);
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadedFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.fileName === file.name ? { ...f, progress } : f
            )
          );
        }
      });

      xhr.open("PUT", uploadURL, true);
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.send(file);

      return new Promise<void>((resolve, reject) => {
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              // Update fileKey and progress to 100%
              setUploadedFiles((prevFiles) =>
                prevFiles.map((f) =>
                  f.fileName === file.name
                    ? { ...f, fileKey, progress: 100 }
                    : f
                )
              );
              resolve();
            } else {
              reject(new Error("Failed to upload file"));
            }
          }
        };
      });
    } catch (error) {
      console.error("Failed to upload file:", error);
    }
  };

  const handleFileRemove = (fileName: string) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.fileName !== fileName)
    );
  };

  return { uploadedFiles, handleFileChange, handleFileRemove };
};
