import { useState, useEffect } from "react";
import { API_URL } from "src/util/config";
import { checkResponse } from "src/util/helpers";
import { RecurringInvoiceQc } from "models/recurring-invoice-qc";
import { useAuth } from "src/contexts/AuthContext";

export const useRecurringInvoiceQcRequests = () => {
  const [recurringInvoiceQcRequests, setRecurringInvoiceQcRequests] = useState<
    RecurringInvoiceQc[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { fetchWithReauth } = useAuth();

  const fetchRecurringInvoiceQcRequestsByMonth = async (yearMonth?: string) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      if (yearMonth) {
        queryParams.append("yearMonth", yearMonth);
      }

      const response = await fetchWithReauth(
        `${API_URL}/invoices/recurring-invoice-qc?${queryParams.toString()}`,
        { method: "GET" }
      );
      const data = await checkResponse(response);
      setRecurringInvoiceQcRequests(data.items);
    } catch (error) {
      console.error("Error fetching recurring invoice QC requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecurringInvoiceQcRequestsByMonth(); // Fetch for the current month by default
  }, []);

  return {
    recurringInvoiceQcRequests,
    loading,
    fetchRecurringInvoiceQcRequestsByMonth,
  };
};
