import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import throttle from "lodash/throttle";

import { useInvoiceLookupByTransactionId } from "src/hooks/po-number-reissue/useInvoiceLookupByTransactionId";
import { useCreatePoNumberReissueRequest } from "src/hooks/po-number-reissue/useCreatePoNumberReissueRequest";
import { useFileUpload } from "src/hooks/useFileUpload";
import FileItem from "../general/FileItem"; // Import the clean FileItem component

const PoReissueForm: React.FC = () => {
  const [transactionId, setTransactionId] = useState<string>(""); // This is the input field state
  const [enteredTransactionId, setEnteredTransactionId] = useState<string>(""); // This state is used to trigger the lookup
  const [poNumber, setPoNumber] = useState<string>("");
  const [invoiceData, setInvoiceData] = useState<any | null>(null);
  const [isUpdateComplete, setIsUpdateComplete] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    invoice,
    loading: lookupLoading,
    error,
  } = useInvoiceLookupByTransactionId(enteredTransactionId); // Lookup happens only when transaction ID is entered

  const {
    createPoNumberReissueRequest,
    loading: updateLoading,
    result,
  } = useCreatePoNumberReissueRequest();

  const { uploadedFiles, handleFileChange, handleFileRemove } = useFileUpload();

  useEffect(() => {
    if (invoice) {
      setInvoiceData(invoice);
    }
  }, [invoice]);

  useEffect(() => {
    if (result) {
      setIsUpdateComplete(true);
      // Automatically navigate back to the menu after success
      navigate("/po-reissues");
    }
  }, [result, navigate]);

  const handleTransactionIdChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTransactionId(event.target.value);
  };

  const handleTransactionIdBlur = () => {
    setEnteredTransactionId(transactionId); // Trigger the lookup on blur
  };

  const handlePoNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPoNumber(event.target.value);
  };

  const handleUpdate = throttle(async () => {
    const fileKey =
      uploadedFiles.length > 0
        ? uploadedFiles.map((file) => file.fileKey)[0]
        : undefined;
    await createPoNumberReissueRequest(enteredTransactionId, poNumber, fileKey);
  }, 3000); // Throttle button click to once every 3 seconds

  const isSubmitDisabled =
    !invoiceData ||
    !poNumber ||
    lookupLoading ||
    updateLoading ||
    uploadedFiles.some((f) => f.progress !== 100);

  return (
    <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center" }}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mt: 3,
          mb: 3,
          minHeight: "60vh",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "80vw",
        }}
      >
        <Typography variant="h5" component="h1" align="center">
          PO Number Reissue
        </Typography>

        {/* Invoice Lookup Table */}
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>Invoice Amount</TableCell>
                <TableCell>Current PO Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{invoiceData?.transactionId || "..."}</TableCell>
                <TableCell>{invoiceData?.customerName || "..."}</TableCell>
                <TableCell>{invoiceData?.invoiceAmount || "..."}</TableCell>
                <TableCell>{invoiceData?.poNumber || "..."}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TextField
          label="Transaction ID"
          variant="outlined"
          fullWidth
          value={transactionId}
          onChange={handleTransactionIdChange}
          onBlur={handleTransactionIdBlur} // Perform lookup on blur (input field loses focus)
          disabled={lookupLoading || updateLoading}
        />

        {error && (
          <Typography variant="body2" color="error" align="center">
            {error}
          </Typography>
        )}

        <TextField
          label="New PO Number"
          variant="outlined"
          fullWidth
          value={poNumber}
          onChange={handlePoNumberChange}
          sx={{ mt: 2 }}
        />

        {/* File Upload Section */}
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Upload PO from Customer</Typography>
          {uploadedFiles.length === 0 ? (
            <Button component="label" variant="text" startIcon={<AddIcon />}>
              Add File
              <Input
                type="file"
                onChange={(event) =>
                  handleFileChange(event as React.ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />
            </Button>
          ) : (
            <Button
              component="label"
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => handleFileRemove(uploadedFiles[0].fileName)}
            >
              Change File
              <Input
                type="file"
                onChange={(event) =>
                  handleFileChange(event as React.ChangeEvent<HTMLInputElement>)
                }
                style={{ display: "none" }}
              />
            </Button>
          )}
          <Box sx={{ mt: 2 }}>
            {uploadedFiles.length > 0 && (
              <FileItem
                key={uploadedFiles[0].fileName}
                file={uploadedFiles[0]}
                onRemove={handleFileRemove}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={isSubmitDisabled}
          >
            {updateLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Update PO Number"
            )}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PoReissueForm;
